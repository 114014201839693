import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import lazysizes from 'lazysizes';

interface HTMLElementMapping {
    [index: string]: HTMLElement;
}

export default class NestedMenuPlugin extends Plugin {
    protected outerTriggerContainerSelector: string;
    protected nestedMenuTriggerSelector: string;
    protected nestedMenuSelector: string;
    protected dataMenu: string;
    protected dataMenuLevel: string;
    protected dataMenuParent: string;
    protected dataMenuTeaser: string;
    protected dataMenuImage: string;
    protected dataMenuTrigger: string;
    protected modalOpenClass: string;
    protected nestedMenuTriggers: NodeListOf<HTMLElement> | null;
    protected nestedMenus: NodeListOf<HTMLElement> | null;
    protected outerTriggerContainers: NodeListOf<HTMLElement> | null;
    protected imageSelector: string;
    protected teaserSelector: string;
    protected menuMapping: HTMLElementMapping;
    protected imageMapping: HTMLElementMapping;
    protected teaserMapping: HTMLElementMapping;
    protected menuActiveClass: string;
    protected dataLevel: string;
    protected dataTargetLevel: string;
    protected mouseLeaveTimer: any;
    protected mouseEnterTimer: any;
    protected menuLevel: string;
    protected menuId: string;
    protected interactionDelay: number;
    protected submenuActiveClass: string;

    constructor() {
        super('NestedMenuPlugin');

        this.nestedMenuTriggerSelector = '[data-menu-trigger]';
        this.nestedMenuSelector = '[data-menu]';
        this.outerTriggerContainerSelector = '[data-outer-menu-container]';
        this.imageSelector = '[data-menu-image]';
        this.teaserSelector = '[data-menu-teaser]';
        this.dataMenuLevel = 'menuLevel';
        this.dataMenuImage = 'menuImage';
        this.dataMenuTeaser = 'menuTeaser';
        this.dataMenuParent = 'menuParent';
        this.dataMenuTrigger = 'menuTrigger';
        this.dataMenu = 'menu';
        this.menuActiveClass = 'js-nested-menu--active';
        this.dataLevel = 'menuLevel';
        this.dataTargetLevel = 'menuTargetLevel';
        this.modalOpenClass = 'modal-open';
        this.submenuActiveClass = 'submenu__navigation-link--active';

        this.nestedMenuTriggers = null;
        this.nestedMenus = null;
        this.outerTriggerContainers = null;

        this.menuMapping = {};
        this.imageMapping = {};
        this.teaserMapping = {};

        this.mouseEnterTimer = -1;
        this.mouseLeaveTimer = -1;
        this.interactionDelay = 330;

        this.menuLevel = "1";
        this.menuId = "-1";
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        let instance = this;
        super.initPlugin(htmlElement);

        if (this.el === undefined) {
            return false;
        }

        let menuTriggers = this.el.querySelectorAll(this.nestedMenuTriggerSelector);
        this.outerTriggerContainers = document.querySelectorAll(this.outerTriggerContainerSelector);

        this.outerTriggerContainers.forEach(function(outerTrigger) {
            menuTriggers = [
                ...menuTriggers,
                ...outerTrigger.querySelectorAll(instance.nestedMenuTriggerSelector)
            ] as any;
        });

        this.nestedMenuTriggers = menuTriggers as NodeListOf<HTMLElement>;
        this.nestedMenus = this.el.querySelectorAll(this.nestedMenuSelector);

        this.nestedMenus.forEach(function (menuElement) {
            let menuId = menuElement.dataset[instance.dataMenu];

            if (menuId === undefined) {
                throw new Error('Invalid menu element supplied: "' + menuElement + '"');
                return false;
            }

            instance.menuMapping[menuId] = menuElement;
        });

        let images = this.el.querySelectorAll(this.imageSelector) as NodeListOf<HTMLElement>;
        let teaser = this.el.querySelectorAll(this.teaserSelector) as NodeListOf<HTMLElement>;

        images.forEach(function(image) {
            let menuId = image.dataset[instance.dataMenuImage];

            if (menuId === undefined) {
                throw new Error('Invalid image element supplied: "' + menuId + '"');
                return false;
            }

            instance.imageMapping[menuId] = image;
        });

        teaser.forEach(function(teaser) {
            let menuId = teaser.dataset[instance.dataMenuTeaser];

            if (menuId === undefined) {
                throw new Error('Invalid teaser element supplied: "' + menuId + '"');
                return false;
            }

            instance.teaserMapping[menuId] = teaser;
        });

        for (let menuElIndex in this.menuMapping) {
            let menuEl = this.menuMapping[menuElIndex];

            if (menuEl.dataset[this.dataLevel] === undefined) {
                throw new Error('Can not evaluate menu level - data attribute is missing');
            }

            if (menuEl.dataset[this.dataLevel] === "1") {
                menuEl.addEventListener('mouseleave', instance.onMouseLeaveMainMenu.bind(instance));
                menuEl.addEventListener('mouseenter', instance.onMouseEnterMainMenu.bind(instance));
            }
        }

        this.nestedMenuTriggers.forEach(function(trigger) {
            trigger.addEventListener('mouseenter', instance.onHoverTrigger.bind(instance));
            trigger.addEventListener('mouseleave', instance.onTriggerLeave.bind(instance));
        });

        setTimeout(function () {
            images.forEach(function(image) {
                image.classList.add('lazyload');
            });

            lazysizes.init();
        }, 500);

        return true;
    }

    onMouseEnterMainMenu(): void {
        clearTimeout(this.mouseLeaveTimer);
    }

    onMouseLeaveMainMenu(event: MouseEvent): void {
        let instance = this;
        let targetEl = event.currentTarget as HTMLElement;

        clearTimeout(this.mouseLeaveTimer);

        this.mouseLeaveTimer = setTimeout(function() {
            instance.menuLevel = "0";
            instance.closeImages();
            instance.closeTeasers();
            instance.closeMenus(targetEl, undefined);
        }, this.interactionDelay);
    }

    onHoverTrigger(event: MouseEvent): void {
        let instance = this;
        let targetEl = event.currentTarget as HTMLElement;

        clearTimeout(this.mouseEnterTimer);

        this.mouseEnterTimer = setTimeout(function() {
            let menuTargetId = targetEl.dataset[instance.dataMenuTrigger];
            let menuTargetLevel = targetEl.dataset[instance.dataTargetLevel];

            if (menuTargetId === undefined) {
                throw new Error('Data attribute "' + instance.dataMenuTrigger + '" must be defined on element');
            }

            if (menuTargetLevel === undefined) {
                throw new Error('Data attribute "' + instance.dataTargetLevel + '" must be defined on element');
            }

            instance.menuId = menuTargetId;
            instance.menuLevel = menuTargetLevel;

            let menuEL = instance.menuMapping[menuTargetId];
            let imageEl = instance.imageMapping[menuTargetId];
            let teaserEl = instance.teaserMapping[menuTargetId];

            instance.openMenu(menuEL, imageEl, teaserEl, targetEl);
        }, this.interactionDelay);
    }

    onTriggerLeave(event: MouseEvent): void {
        let instance = this;
        let targetEl = event.currentTarget as HTMLElement;

        clearTimeout(this.mouseEnterTimer);
        clearTimeout(this.mouseLeaveTimer);

        this.mouseLeaveTimer = setTimeout(function() {
            let menuLevel = targetEl.dataset[instance.dataMenuLevel];
            let menuTargetId = targetEl.dataset[instance.dataMenuTrigger];

            if (
                menuLevel !== undefined
                && menuTargetId !== undefined
                && parseInt(menuLevel) === 0
                && !instance.menuMapping[menuTargetId]
            ) {
                targetEl.classList.remove(instance.menuActiveClass);
                targetEl.classList.remove(instance.submenuActiveClass);
            }
        }, this.interactionDelay);
    }

    openMenu(
        menuEl: HTMLElement | undefined,
        teaserEl: HTMLElement | undefined,
        imageEl: HTMLElement | undefined,
        trigger: HTMLElement
    ): void {
        this.closeMenus(menuEl, trigger);
        this.closeImages();
        this.closeTeasers();

        trigger.classList.add(this.menuActiveClass);
        trigger.classList.add(this.submenuActiveClass);

        document.body.classList.add(this.modalOpenClass);

        if (this.menuLevel >= "1") {
            this.el.classList.add(this.menuActiveClass);
        }

        if (menuEl !== undefined) {
            menuEl.classList.add(this.menuActiveClass);
        }

        if (imageEl !== undefined) {
            imageEl.classList.add(this.menuActiveClass);
        }

        if (teaserEl !== undefined) {
            teaserEl.classList.add(this.menuActiveClass);
        }
    }

    closeTeasers(): void {
        for (let teaserId in this.teaserMapping) {
            let teaser = this.teaserMapping[teaserId];

            teaser.classList.remove(this.menuActiveClass);
        }
    }

    closeImages(): void {
        for (let imageId in this.imageMapping) {
            let image = this.imageMapping[imageId];

            image.classList.remove(this.menuActiveClass);
        }
    }

    closeMenus(menuEl: HTMLElement | undefined, trigger: HTMLElement | undefined): void {
        let instance = this;

        document.body.classList.remove(this.modalOpenClass);

        if (menuEl !== undefined) {
            menuEl.classList.remove(this.menuActiveClass);
            menuEl.classList.remove(this.submenuActiveClass);

            // @ts-ignore
            if (menuEl.dataset[this.dataLevel] !== undefined && Number(menuEl.dataset[this.dataLevel]) <= 1) {
                this.el.classList.remove(this.menuActiveClass);
                this.el.classList.remove(this.submenuActiveClass);
            }
        }

        if (Number(this.menuLevel) === 0) {
            this.el.classList.remove(this.menuActiveClass);
            this.el.classList.remove(this.submenuActiveClass);
        }

        if (this.nestedMenuTriggers === null) {
            throw new Error('No menu triggers defined');
        }

        if (trigger !== undefined) {
            let menuLevel = trigger.dataset[this.dataLevel];

            if (menuLevel === undefined) {
                throw new Error(
                    'Can not evaluate target level. Data attribute: "' + instance.dataTargetLevel + '" is missing'
                );
            }

            this.nestedMenuTriggers.forEach(function(currentTrigger) {
                let currentMenuLevel = currentTrigger.dataset[instance.dataLevel];

                if (currentMenuLevel === undefined) {
                    throw new Error(
                        'Can not evaluate menu level. Data attribute: "' + instance.dataLevel + '" is missing'
                    );
                }

                if (Number(menuLevel) <= Number(currentMenuLevel)) {
                    currentTrigger.classList.remove(instance.menuActiveClass);
                    currentTrigger.classList.remove(instance.submenuActiveClass);
                }
            });
        } else {
            this.nestedMenuTriggers.forEach(function(currentTrigger) {
                currentTrigger.classList.remove(instance.menuActiveClass);
                currentTrigger.classList.remove(instance.submenuActiveClass);
            });
        }

        for (let menuElIndex in this.menuMapping) {
            let currentMenuEl = this.menuMapping[menuElIndex];

            if (currentMenuEl.dataset[this.dataLevel] === undefined) {
                throw new Error('Can not evaluate menu level - data attribute is missing');
            }

            // @ts-ignore
            if (Number(this.menuLevel) <= Number(currentMenuEl.dataset[this.dataLevel])) {
                currentMenuEl.classList.remove(this.menuActiveClass);
                currentMenuEl.classList.remove(this.submenuActiveClass);
            }
        }
    }
}
