import Plugin from "../plugin-system/Plugin";

export default class ServiceBadgePlugin extends Plugin {
    constructor() {
        super('ServiceBadgePlugin');
    }

    /**
     * @param htmlElement
     */
    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        let badgeTrigger = this.el.querySelector('.service-badge__trigger');
        let closeTrigger = this.el.querySelector('.service-badge__close');

        if (badgeTrigger !== null && closeTrigger !== null) {
            badgeTrigger.addEventListener('click', this.toggleBadgeState.bind(this));
            closeTrigger.addEventListener('click', this.toggleBadgeState.bind(this));
        }

        return true;
    }

    toggleBadgeState(): void {
        let instance = this;

        instance.el.parentElement!.classList.toggle('service-badge--open');
        instance.el.classList.toggle('service-badge--open');
    }
}
