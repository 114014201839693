import Plugin from "../plugin-system/Plugin";

export default class TextImageCombinationPlugin extends Plugin {
    constructor(pluginName = 'TextImageCombinationPlugin') {
        super(pluginName);
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.store.textEl = this.el.querySelector('.text-image-combination__text-container');
        this.store.imageEl = this.el.querySelector('.text-image-combination__image-container');

        if (this.store.textEl && this.store.imageEl) {
            this.onResize();

            this.registerEvents();
        }

        return true;
    }

    registerEvents(): void {
        window.addEventListener('resize', this.onResize.bind(this));
    }

    onResize(): void {
        let imageEl = this.store.imageEl as HTMLElement;

        imageEl.style.minHeight = 'auto';

        if (window.innerWidth > 767) {
            this.resizeImageElement();
        }
    }

    resizeImageElement(): void {
        let textEl = this.store.textEl as HTMLElement;
        let imageEl = this.store.imageEl as HTMLElement;

        imageEl.style.minHeight = textEl.offsetHeight + 'px';

        if (imageEl.offsetHeight < textEl.offsetHeight) {
            this.resizeImageElement();
        }
    }
}
