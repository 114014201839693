import Plugin from "../plugin-system/Plugin";
import scrollto from '../utilities/scrollto';

export interface Viewports {
    [index: string]: number;
}

export default class AnchorScrollPlugin extends Plugin {
    protected anchorTarget: HTMLElement | null;
    protected anchorTargets: NodeListOf<HTMLElement> | null;
    protected anchorTargetsSelector: string;
    protected locationHash: string;
    protected onElementClicked: boolean;
    protected viewports: Viewports;
    protected currentViewport: number;
    protected scrollDuration: number;

    constructor() {
        super('ScrollMenuPlugin');

        this.locationHash = location.hash;
        this.onElementClicked = false;

        this.anchorTarget = null;
        this.anchorTargets = null;
        this.anchorTargetsSelector = '[data-anchor-target]';

        this.viewports = {
            sm: 767,
            md: 1199,
            xl: 9999
        };

        this.currentViewport = this.viewports.sm;
        this.scrollDuration = 330;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.anchorTargets = this.el.querySelectorAll(this.anchorTargetsSelector);
        this.currentViewport = this.detectViewport();

        this.registerEvents();

        return true;
    }

    registerEvents(): void {
        let instance = this;

        window.addEventListener('resize', this.onResize.bind(this));
        window.addEventListener('load', this.onLoad.bind(this));
        window.addEventListener('hashchange', this.onHashChange.bind(this));

        this.anchorTargets!.forEach(anchorTarget => {
            anchorTarget.addEventListener('click', () => {
                instance.anchorTarget = anchorTarget;
                instance.onAnchorClick();
            });
        });
    }

    onResize(): void {
        this.currentViewport = this.detectViewport();
    }

    onLoad(): void {
        this.locationHash = location.hash;
        setTimeout(this.onNavigate.bind(this));
    }

    onHashChange(): void {
        if (!this.onElementClicked) {
            this.locationHash = location.hash;
            this.onNavigate();
        }
    }

    onAnchorClick(): void {
        this.onElementClicked = true;
        this.locationHash = this.anchorTarget!.dataset.anchorTarget ?? '';
        this.onNavigate();
    }

    onNavigate(): void {
        let currentHash = this.locationHash;

        if (currentHash !== '') {
            let targetEl = document.querySelector('*[data-anchor="' + currentHash + '"]');

            if (targetEl !== null) {
                let targetElPos = targetEl.getBoundingClientRect();
                let targetElPosY = targetElPos.y || targetElPos.top;
                let scrollTop = this.detectScrollPosition();
                let scrollPosition = scrollTop + targetElPosY;

                scrollto(
                    document.scrollingElement || document.documentElement,
                    scrollPosition,
                    this.scrollDuration
                );
            }
        }

        setTimeout(this.onNavigatingFinished.bind(this), this.scrollDuration);
    }

    onNavigatingFinished(): void {
        this.onElementClicked = false;
    }

    detectScrollPosition(): number {
        return document.body.scrollTop || document.documentElement.scrollTop;
    }

    detectViewport(): number {
        if (window.innerWidth < this.viewports.sm) {
            return this.viewports.sm
        } else if(window.innerWidth < this.viewports.md) {
            return this.viewports.md;
        } else {
            return this.viewports.xl;
        }
    }
}
