let easeInOutQuad = function (t: number, b: number, c: number, d: number): number {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};

export default function scrollTo(element: Element, to: number, duration: number): void {
    let start = element.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20;

    let animateScroll = function(): void {
        currentTime += increment;
        let val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;

        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        } else {
            element.scrollTop = to;
        }
    };

    animateScroll();
}
