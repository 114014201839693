import Plugin from "../plugin-system/Plugin";
import $ from 'jquery';

export default class SearchPlugin extends Plugin {
    protected _body: HTMLElement | null;
    protected _layer: HTMLElement | null;
    protected _input: HTMLElement | null;

    constructor() {
        super('SearchPlugin');

        this._body = null;
        this._layer = null;
        this._input = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        let me = this;

        super.initPlugin(htmlElement);

        if (me.el === undefined) {
            return false;
        }

        me._body = document.querySelector('body');
        me._layer = document.querySelector('.search-layer');
        me._input = document.querySelector('.search-field-wrapper input');

        document.addEventListener('keydown', me.onKeyDown.bind(me));
        me.el.addEventListener('click', me.onSearchIconClick.bind(me));

        if (me._body) {
            me._body.addEventListener('click', me.onBodyClick.bind(me));
        }

        me.addAutoComplete();

        return true;
    }

    onSearchIconClick(event: Event): void {
        let me = this;

        if (!me._layer || !me._body) {
            return;
        }

        me._body.classList.toggle('modal-open');
        me._layer.classList.toggle('is-active');

        if (me._layer.classList.contains('is-active')) {
            me._layer.querySelector('input')?.focus();
        }
        event.stopPropagation();
    }

    onKeyDown(event: KeyboardEvent): void {
        let me = this;

        if (!me._layer || !me._body) {
            return;
        }

        if (event.code == 'Escape') {
            me._body.classList.remove('modal-open');
            me._layer.classList.remove('is-active');
        }
    }

    onBodyClick(event: Event): void {
        let target = event.target,
            me = this;

        if (!(target instanceof HTMLElement) || !me._layer || !me._body) {
            return;
        }

        if (!target.classList.contains('search-layer-content') && !target.closest('.search-layer-content')) {
            me._body.classList.remove('modal-open');
            me._layer.classList.remove('is-active');
        }
    }

    addAutoComplete(): void {
        let $el = $('input.search-field'),
            language = $el.data('language'),
            country = $el.data('country'),
            $categoryEl = $el.closest('form').find('select.categories'),
            categories = [];

        $el.autocomplete({
            minChars: 3,
            triggerSelectOnValidInput: false,
            lookup: function(term, done) {
                categories = $categoryEl.val();
                $.getJSON(
                    '/lucence-search/auto-complete',
                    {
                        q: term,
                        language : language,
                        country: country,
                        categories: categories
                    },
                    function(data) {
                        let result: any = { suggestions: [] };

                        if(data.length > 0) {
                            $.each(data, function(index, suggestion) {
                                result.suggestions.push({ "value" : suggestion });
                            });
                        }

                        done(result);
                    });
            },
            onSelect: function(result) {
                $el.val(result.value);
                $el.parents('form').submit();
            }
        });
    }
}