import Plugin from "../plugin-system/Plugin";

export interface ScrollBreakPoints {
    sm: number;
    md: number;
    xl: number;
}

export interface Viewports {
    [index: string]: number;
}

export default class ScrollMenuPlugin extends Plugin {
    protected navigation: HTMLElement | null;
    protected navigationHiddenClass: string;
    protected bodyNavigationHiddenClass: string;
    protected navigationCollapsedClass: string;
    protected navigationListItemLinks: NodeListOf<HTMLElement> | null;
    protected navigationListItemLinkSelector: string;
    protected navigationListItemActiveClass: string;
    protected navigationActiveListItem: HTMLElement | null;
    protected anchors: NodeListOf<HTMLElement> | null;
    protected anchorsSelector: string;
    protected anchorCurrent: string;
    protected viewports: Viewports;
    protected scrollBreakPoints: ScrollBreakPoints;
    protected currentViewport: number;
    protected currentScrollPosition: number;
    protected scrollDownOffset: number;
    protected scrollDownMinimum: number;

    constructor() {
        super('ScrollMenuPlugin');

        this.navigation = null;
        this.navigationHiddenClass = 'navigation--hidden';
        this.bodyNavigationHiddenClass = 'page--navigation-hidden';
        this.navigationCollapsedClass = 'navigation--collapsed';
        this.navigationListItemLinks = null;
        this.navigationListItemLinkSelector = '.navigation__list-item--link';
        this.navigationListItemActiveClass = 'navigation__list-item--active';
        this.navigationActiveListItem = null;

        this.anchors = null;
        this.anchorsSelector = '[data-anchor]';
        this.anchorCurrent = '';

        this.viewports = {
            sm: 767,
            md: 1199,
            xl: 9999
        };

        this.scrollBreakPoints = {
            sm: 0,
            md: 0,
            xl: 160
        }

        this.currentViewport = this.viewports.sm;
        this.currentScrollPosition = 0;

        this.scrollDownMinimum = 100;
        this.scrollDownOffset = 0;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.currentScrollPosition = this.detectScrollPosition();
        this.currentViewport = this.detectViewport();

        this.navigation = this.el;
        this.navigationListItemLinks = this.el.querySelectorAll(this.navigationListItemLinkSelector);

        this.anchors = document.querySelectorAll(this.anchorsSelector);

        this.registerEvents();

        return true;
    }

    registerEvents(): void {
        window.addEventListener('scroll', this.onScroll.bind(this));
        window.addEventListener('resize', this.onResize.bind(this));
    }

    onResize(): void {
        this.currentViewport = this.detectViewport();

        this.setHeaderState();
        this.updateNavigationItems();
    }

    onScroll(): void {
        this.setHeaderState();
        this.updateNavigationItems();
    }

    updateNavigationItems(): void {
        let instance = this,
            scrollTop = this.detectScrollPosition(),
            isInAnchor = false;

        this.anchors!.forEach(anchor => {
            let anchorPos = anchor.getBoundingClientRect();
            let anchorPosY = anchorPos.y || anchorPos.top;
            let anchorName = anchor.dataset.anchor ?? '';

            if (
                scrollTop >= anchorPosY + scrollTop - 1
                && scrollTop <= anchorPosY + scrollTop + anchor.clientHeight
            ) {
                isInAnchor = true;

                if (instance.anchorCurrent !== anchorName) {
                    let activeNavItem = instance.el.querySelector('*[data-anchor-target="' + anchorName + '"]');

                    instance.navigationListItemLinks?.forEach(listItem => {
                        listItem.parentElement!.classList.remove(instance.navigationListItemActiveClass);
                    })
                    instance.anchorCurrent = anchorName;

                    if (activeNavItem !== null) {
                        activeNavItem.parentElement!.classList.add(instance.navigationListItemActiveClass);
                    }
                }
            }
        });

        if (!isInAnchor && instance.anchorCurrent !== '') {
            instance.anchorCurrent = '';

            instance.navigationListItemLinks?.forEach(listItem => {
                listItem.parentElement!.classList.remove(instance.navigationListItemActiveClass);
            })
        }
    }

    setHeaderState(): void {
        if (this.currentViewport <= this.viewports.md) {
            return;
        }

        let scrollDirection = 'down',
            scrollOffset = this.scrollBreakPoints.sm,
            scrollPosition = this.detectScrollPosition();
            this.scrollDownOffset += scrollPosition - this.currentScrollPosition;

        if (this.currentScrollPosition > scrollPosition) {
            scrollDirection  = 'up';
            this.scrollDownOffset = 0;
        }

        this.currentScrollPosition = scrollPosition;

        if (this.currentViewport === this.viewports.sm) {
            scrollOffset = this.scrollBreakPoints.sm;
        } else if (this.currentViewport === this.viewports.md) {
            scrollOffset = this.scrollBreakPoints.md;
        } else {
            scrollOffset = this.scrollBreakPoints.xl;
        }

        if (
            scrollDirection === 'down'
            && scrollOffset - scrollPosition <= 0
            && this.scrollDownOffset > this.scrollDownMinimum
        ) {
            this.navigation!.classList.add(this.navigationHiddenClass);
            document.body.classList.add(this.bodyNavigationHiddenClass);
        }

        if (scrollDirection === 'up') {
            this.navigation!.classList.remove(this.navigationHiddenClass);
            document.body.classList.remove(this.bodyNavigationHiddenClass);
        }
    }

    detectScrollPosition(): number {
        return window.scrollY;
    }

    detectViewport(): number {
        if (window.innerWidth < this.viewports.sm) {
            return this.viewports.sm
        } else if(window.innerWidth < this.viewports.md) {
            return this.viewports.md;
        } else {
            return this.viewports.xl;
        }
    }
}
