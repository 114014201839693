import Plugin from "../plugin-system/Plugin";
import Animator from "../helper/Animator";

export default class MobileNavigationPlugin extends Plugin {
    protected slideContainerSelector: string;
    protected mobileNavigationItemSelector: string;
    protected mobileNavigationItems: NodeListOf<HTMLElement> | null;
    protected slideContainer: HTMLElement | null;
    protected slideSelector: string;
    protected slideMap: NodeListOf<HTMLElement> | null;
    protected currentSlide: HTMLElement | null;
    protected currentSlideSelector: string;
    protected currentSlideClass: string;
    protected backButton: HTMLElement | null;
    protected backButtonSelector: string;
    protected backButtonActiveClass: string;
    protected slideFadingClass: string;
    protected slideFadeInClass: string;
    protected slideFadeOutClass: string;
    protected slideFadeOutRightClass: string;
    protected backButtonFadeClass: string;
    protected navigationTrigger: HTMLElement | null;
    protected navigationTriggerSelector: string;
    protected navigationActiveClass: string;
    protected navigationFadeOutClass: string;
    protected navigationCloseTriggerSelector: string;
    protected navigationCloseTrigger: HTMLElement | null;
    protected navigationOpen: boolean;
    protected modalOpenClass: string;
    protected animationSpeed: number;

    constructor() {
        super('MobileNavigationPlugin');

        this.slideContainerSelector = '.mobile-navigation__slide-container';
        this.mobileNavigationItemSelector = '[data-navigation-slide-target]';
        this.slideSelector = '[data-navigation-slide]';
        this.currentSlideSelector = '.js-mobile-navigation__slide--active';
        this.currentSlideClass = 'js-mobile-navigation__slide--active';
        this.slideFadingClass = 'js-mobile-navigation__slide--fading';
        this.slideFadeInClass = 'js-mobile-navigation__slide--fade-in';
        this.slideFadeOutClass = 'js-mobile-navigation__slide--fade-out';
        this.slideFadeOutRightClass = 'js-mobile-navigation__slide--fade-out-right';
        this.backButtonActiveClass = 'js-mobile-navigation__back-button--active';
        this.backButtonSelector = '.js-mobile-navigation__back-button';
        this.backButtonFadeClass = 'js-mobile-navigation__back-button--fade';
        this.navigationTriggerSelector = '.js-mobile-navigation__trigger';
        this.navigationActiveClass = 'mobile-navigation--collapsed';
        this.navigationFadeOutClass = 'navigation--fade-out';
        this.navigationCloseTriggerSelector = '.js-mobile-navigation__close-button';
        this.modalOpenClass = 'modal-open';

        this.navigationCloseTrigger = null;
        this.navigationTrigger = null;
        this.slideContainer = null;
        this.mobileNavigationItems = null;
        this.slideMap = null;
        this.currentSlide = null;
        this.backButton = null;

        this.navigationOpen = false;
        this.animationSpeed = 330;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        let instance = this;
        this.slideContainer = this.el.querySelector(this.slideContainerSelector);
        this.mobileNavigationItems = this.el.querySelectorAll(this.mobileNavigationItemSelector);
        this.slideMap = this.el.querySelectorAll(this.slideSelector);
        this.currentSlide = this.el.querySelector(this.currentSlideSelector);
        this.backButton = this.el.querySelector(this.backButtonSelector);
        this.navigationTrigger = document.querySelector(this.navigationTriggerSelector);
        this.navigationCloseTrigger = document.querySelector(this.navigationCloseTriggerSelector);

        this.mobileNavigationItems.forEach(function (element) {
            element.addEventListener('click', instance.navigationItemClicked.bind(instance));
        });

        if (this.navigationTrigger !== null) {
            this.navigationTrigger.addEventListener('click', instance.openNavigation.bind(instance));
        }

        if (this.navigationCloseTrigger !== null) {
            this.navigationCloseTrigger.addEventListener('click', instance.closeNavigation.bind(instance));
        }

        if (this.backButton !== null) {
            this.backButton.addEventListener('click', instance.backButtonClicked.bind(instance));
        }

        document.addEventListener('click', instance.documentClicked.bind(instance));

        return true;
    }

    protected documentClicked(event: MouseEvent): void {
        if (this.navigationOpen && !this.el.contains(event.target as HTMLElement)) {
            this.closeNavigation();
        }
    }

    protected openNavigation(): void {
        let instance = this;

        if (!this.el.classList.contains(this.navigationActiveClass)) {
            // Muss per Timeout gesetzt werden, da ansonsten das Menü beim öffnen direkt wieder geschlossen wird.
            setTimeout(function () {
                instance.navigationOpen = true;
            }, 100);

            this.el.classList.add(this.navigationActiveClass);
            document.querySelector('body')?.classList.add(this.modalOpenClass);
        } else {
            this.el.classList.remove(this.navigationActiveClass);
            this.el.classList.add(this.navigationFadeOutClass);
            document.querySelector('body')?.classList.remove(this.modalOpenClass);

            // Muss per Timeout gesetzt werden, da ansonsten das Menü beim Schließen direkt verschwindet.
            setTimeout(() => {
                instance.el.classList.remove(instance.navigationFadeOutClass);
            }, instance.animationSpeed);
        }
    }

    protected closeNavigation(): void {
        let instance = this;

        this.el.classList.remove(this.navigationActiveClass);
        this.el.classList.add(this.navigationFadeOutClass);
        document.querySelector('body')?.classList.remove(this.modalOpenClass);

        // Muss per Timeout gesetzt werden, da ansonsten das Menü beim Schließen direkt verschwindet.
        setTimeout(() => {
            instance.el.classList.remove(instance.navigationFadeOutClass);
        }, instance.animationSpeed);

        this.navigationOpen = false;
    }

    protected backButtonClicked(): void {
        let parentSlideId: string | undefined;
        let targetSlideElement: HTMLElement | undefined;

        if (this.currentSlide === null) {
            throw new Error('Current slide not defined. Cannot detect parent');
        }

        if (this.slideMap === null) {
            throw new Error('There are no slides to target in mobile navigation');
        }

        parentSlideId = this.currentSlide.dataset.parentNavigationSlide;

        this.slideMap.forEach(function(slideElement) {
            if (slideElement.dataset.navigationSlide === parentSlideId) {
                targetSlideElement = slideElement;
            }
        });

        if (targetSlideElement === undefined) {
            throw new Error('Slide target not found: "' + parentSlideId + '"');
        }

        this.changeSlides(targetSlideElement, true);
    }

    /**
     * @param event
     */
    protected navigationItemClicked(event: MouseEvent): void {
        if (this.slideMap === null) {
            throw new Error('There are no slides to target in mobile navigation');
        }

        let clickedElement = event.currentTarget as HTMLElement;
        let dataSlideTarget = clickedElement.dataset.navigationSlideTarget;
        let targetSlideElement: HTMLElement | undefined;

        this.slideMap.forEach(function(slideElement) {
            if (slideElement.dataset.navigationSlide === dataSlideTarget) {
                targetSlideElement = slideElement;
            }
        });

        if (targetSlideElement === undefined) {
            throw new Error('Slide target not found: "' + dataSlideTarget + '"');
        }

        this.changeSlides(targetSlideElement);
    }

    /**
     * @param newSlide
     * @param reverse
     */
    protected changeSlides(newSlide: HTMLElement, reverse = false): void {
        if (this.currentSlide === null) {
            throw new Error('Cannot change slides. The currently active slide is missing');
        }

        let instance = this;
        let currentSlide = this.currentSlide;

        let fadeOutAnimator = new Animator(currentSlide);
        let fadeInAnimator = new Animator(newSlide);

        if (!reverse) {
            fadeInAnimator.addStep({
                callback: function (animator) {
                    newSlide.classList.add(instance.currentSlideClass);
                    newSlide.classList.add(instance.slideFadeInClass);
                    animator.triggerReflow();

                    return animator.nextStep();
                }
            }).run();

            fadeOutAnimator.addStep({
                callback: function (animator) {
                    currentSlide.classList.add(instance.slideFadingClass);
                    currentSlide.classList.add(instance.slideFadeOutClass);
                    currentSlide.classList.remove(instance.currentSlideClass);
                    currentSlide.classList.remove(instance.slideFadeInClass);
                    currentSlide.classList.remove(instance.slideFadeOutRightClass);

                    setTimeout(() => {
                        currentSlide.classList.remove(instance.slideFadingClass);
                    }, instance.animationSpeed);

                    return animator.nextStep();
                }
            }).run();
        } else {
            fadeInAnimator.addStep({
                callback: function (animator) {
                    newSlide.classList.add(instance.currentSlideClass);
                    newSlide.classList.add(instance.slideFadeInClass);
                    newSlide.classList.remove(instance.slideFadeOutClass);
                    animator.triggerReflow();

                    return animator.nextStep();
                }
            }).run();

            fadeOutAnimator.addStep({
                callback: function (animator) {
                    currentSlide.classList.add(instance.slideFadingClass);
                    currentSlide.classList.add(instance.slideFadeOutRightClass);
                    currentSlide.classList.remove(instance.currentSlideClass);
                    currentSlide.classList.remove(instance.slideFadeInClass);

                    setTimeout(() => {
                        currentSlide.classList.remove(instance.slideFadeOutRightClass);
                        currentSlide.classList.remove(instance.slideFadingClass);
                    }, instance.animationSpeed);

                    return animator.nextStep();
                }
            }).run();
        }

        this.currentSlide = newSlide;

        if (this.backButton !== null) {
            let buttonAnimator = new Animator(this.backButton);
            let backButton = this.backButton;

            if (this.currentSlide.dataset.parentNavigationSlide !== undefined) {
                buttonAnimator.addStep({
                    callback: function(animator) {
                        backButton.parentElement?.classList.add(instance.backButtonActiveClass);
                        animator.triggerReflow();

                        return animator.nextStep();
                    }
                }).addStep({
                    addClass: this.backButtonFadeClass
                }).run();
            } else {
                buttonAnimator.addStep({
                    removeClass: this.backButtonFadeClass,
                    callback: function (animator) {
                        return animator.nextStep();
                    }
                }).run();

                backButton.parentElement?.classList.remove(this.backButtonActiveClass);
            }
        }
    }
}
