import Plugin from "../plugin-system/Plugin";

export default class TabPlugin extends Plugin {
    protected tabTargetContainerSelector: string | undefined;
    protected tabElementsSelector: string;
    protected tabActiveClass: string;
    protected tabTitleActiveClass: string;
    protected tabTargetContainerEl: HTMLElement | null;
    protected tabElements: NodeListOf<HTMLElement> | null;

    constructor() {
        super('TabPlugin');

        this.tabElementsSelector = '[data-tab-target]';
        this.tabActiveClass = 'tab--active';
        this.tabTitleActiveClass = 'tab__title--active';

        this.tabTargetContainerSelector = undefined;
        this.tabTargetContainerEl = null;
        this.tabElements = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.tabTargetContainerSelector = this.el.dataset.tabContainer;

        if (!this.tabTargetContainerSelector) {
            return true;
        }

        this.tabTargetContainerEl = document.querySelector(this.tabTargetContainerSelector);
        this.tabElements = this.el.querySelectorAll(this.tabElementsSelector);

        this.registerEvents();

        return true;
    }

    registerEvents(): void {
        let me = this;

        if (!this.tabElements) {
            return;
        }

        this.tabElements.forEach(el => el.addEventListener('click', () => {
            let tabTarget = el.dataset.tabTarget;

            if (tabTarget) {
                me.openTab(el, tabTarget);
            }
        }));
    }

    openTab(tabTitleEl: HTMLElement, tabSelector: string): void {
        let targetTabEl = this.tabTargetContainerEl?.querySelector(tabSelector),
            tabTitles = this.el.querySelectorAll(this.tabElementsSelector),
            activeTabs: NodeListOf<HTMLElement> | undefined =
                this.tabTargetContainerEl?.querySelectorAll('.' + this.tabActiveClass);

        tabTitles?.forEach(el => el.classList.remove(this.tabTitleActiveClass));
        activeTabs?.forEach(el => el.classList.remove(this.tabActiveClass));

        tabTitleEl.classList.add(this.tabTitleActiveClass);

        if (targetTabEl) {
            targetTabEl.classList.add(this.tabActiveClass);
        }
    }
}
