import Plugin from "../plugin-system/Plugin"
import scrollto from '../utilities/scrollto';

export default class ScrollTopPlugin extends Plugin {
    constructor() {
        super('ScrollTopPlugin');
    }

    /**
     * @param htmlElement
     */
    initPlugin(htmlElement: HTMLElement): boolean {
        let instance = this;

        super.initPlugin(htmlElement);

        this.el?.addEventListener('click', instance.elementClicked.bind(instance));

        return true;
    }

    /**
     * @param event
     */
    protected elementClicked(): void {
        scrollto(document.scrollingElement || document.documentElement, 0, 330);
    }
}
