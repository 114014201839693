import Plugin from "../plugin-system/Plugin";

export default class AnchorScrollPlugin extends Plugin {
    protected maxItems: number;
    protected moreDownloadsButton: HTMLElement | null;
    protected downloadRows: NodeListOf<HTMLElement> | null;
    protected downloadRowSelector: string;
    protected moreDownloadsSelector: string;
    protected downloadRowHiddenClass: string;
    protected moreDownloadsVisibleClass: string;
    protected moreDownloadsButtonShowClosedClass: string;

    constructor() {
        super('ScrollMenuPlugin');

        this.maxItems = 5;

        this.downloadRowSelector = '.downloads__table-tr';
        this.moreDownloadsSelector = '.downloads__btn-more';
        this.moreDownloadsVisibleClass = 'downloads__btn-more--visible';
        this.downloadRowHiddenClass = 'downloads__table-tr--hidden';
        this.moreDownloadsButtonShowClosedClass = 'downloads__btn-more--show-closed';

        this.moreDownloadsButton = null;
        this.downloadRows = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.downloadRows = this.el.querySelectorAll(this.downloadRowSelector);
        this.moreDownloadsButton = this.el.querySelector(this.moreDownloadsSelector);

        if (this.moreDownloadsButton !== null && this.downloadRows?.length >= this.maxItems) {
            this.moreDownloadsButton?.classList.add(this.moreDownloadsVisibleClass);
        }

        if (this.downloadRows.length >= this.maxItems) {
            this.hideDownloadList();

            this.moreDownloadsButton?.classList.add(this.moreDownloadsVisibleClass);
        }

        this.registerEvents();

        return true;
    }

    registerEvents(): void {
        let instance = this;

        if (this.moreDownloadsButton !== null) {
            this.moreDownloadsButton.addEventListener('click', this.toggleDownloadList.bind(this));
        }
    }

    hideDownloadList(): void {
        this.downloadRows?.forEach((el, i) => {
            if (i >= this.maxItems) {
                el.classList.add(this.downloadRowHiddenClass);
            }
        });

        this.moreDownloadsButton?.classList.remove(this.moreDownloadsButtonShowClosedClass);
    }

    showDownloadList(): void {
        this.downloadRows?.forEach((el, i) => {
            if (i >= this.maxItems) {
                el.classList.remove(this.downloadRowHiddenClass);
            }
        });

        this.moreDownloadsButton?.classList.add(this.moreDownloadsButtonShowClosedClass);
    }

    // With button click
    toggleDownloadList(): void {
        // Check if list is opened or not - also set label downloads__btn-more--show-list / downloads__btn-more--hide-list
        if (this.moreDownloadsButton?.classList.contains(this.moreDownloadsButtonShowClosedClass)) {
            this.hideDownloadList();
        } else {
            this.showDownloadList();
        }
    }
}
