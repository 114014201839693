import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import PubSub from 'pubsub-js';

export default class BrickManagerPlugin extends Plugin {
    protected brickList: HTMLCollection | null;

    constructor() {
        super('BrickManagerPlugin');

        this.config = {
            expanderClass: 'expander__holder--active',
            expanderPaddingTopClass: 'expander__holder--padding-top',
            expanderPaddingBottomClass: 'expander__holder--padding-bottom'
        }

        this.brickList = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.brickList = this.el.children;

        this.setZIndex();
        this.evaluateAdjustmentClasses();
        this.registerEvents();

        return true;
    }

    protected registerEvents(): void {
        PubSub.subscribe('ExpanderPlugin/showExpander', this.evaluateAdjustmentClasses.bind(this));
        PubSub.subscribe('ExpanderPlugin/hideExpander', this.evaluateAdjustmentClasses.bind(this));
    }

    protected setZIndex(): void {
        if (this.brickList === null) {
            return
        }

        for (let brickId = this.brickList.length - 1; brickId >= 0; brickId--) {
            let brick = this.brickList[brickId] as HTMLElement;

            brick.style.position = 'relative';
            brick.style.zIndex = (this.brickList.length - brickId).toString();
        }
    }

    protected evaluateAdjustmentClasses(): void {
        if (this.brickList === null) {
            return;
        }

        let lastBrick = null;

        for (let brickId = 0; brickId < this.brickList.length; brickId++) {
            // Brick von Markup
            let brick = this.brickList[brickId] as HTMLElement;

            // Brick von Pimcore
            if (brick.classList.contains('pimcore_area_content')) {
                brick = (this.brickList[brickId] as HTMLElement).children[0] as HTMLElement;
            }

            if (typeof brick === 'undefined') {
                continue;
            }

            brick.classList.remove(this.config.expanderPaddingTopClass);
            brick.classList.remove(this.config.expanderPaddingBottomClass);

            if (lastBrick !== null) {
                if (lastBrick.classList.contains(this.config.expanderClass)) {
                    brick.classList.add(this.config.expanderPaddingTopClass);
                }
            }

            if (brick.classList.contains(this.config.expanderClass)) {
                brick.classList.add(this.config.expanderPaddingBottomClass);
            }

            brick.style.position = 'relative';
            brick.style.zIndex = (this.brickList.length - brickId).toString();

            lastBrick = brick;
        }
    }
}
