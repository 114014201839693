import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import PubSub from 'pubsub-js';
// @ts-ignore
import AOS from 'aos';

export default class ScrollAnimationPlugin extends Plugin {
    constructor() {
        super('ScrollAnimationPlugin');
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.registerEvents();

        AOS.init({
            duration: 2000,
            once: true
        });

        return true;
    }

    protected registerEvents(): void {
        PubSub.subscribe('ExpanderPlugin/stateAnimationFinished', this.refreshAOS);

        setTimeout(this.refreshAOS.bind(this), 3000);
    }

    protected refreshAOS(): void {
        AOS.refresh();
    }
}
