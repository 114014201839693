import Plugin from "../plugin-system/Plugin";
import Splide from '@splidejs/splide';

export interface Viewports {
    [index: string]: number;
}

export default class NewsTeaserPlugin extends Plugin {
    protected newsAmount: number;
    protected arrowLeft: HTMLElement | null;
    protected arrowLeftSelector: string;
    protected arrowRight: HTMLElement | null;
    protected slideBullets: NodeListOf<HTMLElement> | null;
    protected slideBulletsSelector: string;
    protected slideBulletHiddenClass: string;
    protected arrowRightSelector: string;
    protected viewports: Viewports;
    protected lastViewport: number;
    protected currentViewport: number;
    protected splide: Splide;

    constructor() {
        super('NewsTeaserPlugin');

        this.splide = new Splide('body');

        this.newsAmount = 0;
        this.arrowLeft = null;
        this.arrowLeftSelector = '.splide__arrow--prev';
        this.arrowRight = null;
        this.arrowRightSelector = '.splide__arrow--next';

        this.slideBullets = null;
        this.slideBulletsSelector = '.splide__pagination__page';
        this.slideBulletHiddenClass = 'splide__pagination__page--hidden';

        this.viewports = {
            sm: 767,
            md: 1199,
            xl: 9999
        };

        this.currentViewport = this.viewports.sm;
        this.lastViewport = this.currentViewport;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.currentViewport = this.detectViewport();
        this.newsAmount = this.el.querySelectorAll(".splide__slide").length;

        this.splide = new Splide(this.el, {type : 'loop'});
        this.setSplideOptions();
        this.splide.mount();

        this.arrowLeft = this.el.querySelector(this.arrowLeftSelector);
        this.arrowRight = this.el.querySelector(this.arrowRightSelector);

        this.slideBullets = this.el.querySelectorAll(this.slideBulletsSelector);

        this.updateArrows();
        this.registerEvents();

        return true;
    }

    registerEvents(): void {
        window.addEventListener('resize', this.onResize.bind(this));
    }

    onResize(): void {
        this.currentViewport = this.detectViewport();

        if (this.detectViewportChange()) {
            this.setSplideOptions();
        }
    }

    detectViewport(): number {
        if (window.innerWidth < this.viewports.sm) {
            return this.viewports.sm
        } else if(window.innerWidth < this.viewports.md) {
            return this.viewports.md;
        } else {
            return this.viewports.xl;
        }
    }

    detectViewportChange(): boolean {
        if (this.lastViewport != this.currentViewport) {
            this.lastViewport = this.currentViewport;

            return true;
        }

        return false;
    }

    setSplideOptions(): void {
        this.splide.options.perPage = 1;

        if (this.currentViewport > this.viewports.sm) {
            this.splide.options.perPage = 2;
        }

        if (this.newsAmount <= 2) {
            this.splide.options.arrows = false;
            this.splide.options.pagination = false;
        }

        this.splide.refresh();
    }

    updateArrows(): void {
        if (this.arrowLeft && this.arrowRight) {
            this.arrowLeft.innerHTML = '<i class="icon-arrow-left"></i>';
            this.arrowRight.innerHTML = '<i class="icon-arrow-right"></i>';
        }
    }
}
