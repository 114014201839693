export interface Store {
    [key: string]: any;
}

export default abstract class Plugin {
    private _el: HTMLElement | undefined;
    private _name: string;
    protected store: Store;
    protected config: Store;

    /**
     *
     * @param name
     */
    public constructor(name: string) {
        this._name = name;

        this.store = {};
        this.config = {};
    }

    get name(): string {
        return this._name;
    }

    get el(): HTMLElement {
        if (this._el === undefined) {
            throw new Error('Element is not defined');
        }

        return this._el;
    }

    /**
     *
     * @param htmlElement
     */
    initPlugin(htmlElement: HTMLElement): boolean {
        this._el = htmlElement;

        return true;
    }
}
