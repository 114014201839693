import Plugin from "../plugin-system/Plugin";

export default class SafariNavigationPlugin extends Plugin {
    protected userAgent: string;
    protected isSafariBrowser: boolean;
    protected navigationIsSafariClass: string;

    constructor() {
        super('SafariNavigationPlugin');

        this.userAgent = navigator.userAgent.toLowerCase();
        this.isSafariBrowser = this.userAgent.indexOf('safari') !== -1 && this.userAgent.indexOf('chrome') === -1;
        this.navigationIsSafariClass = 'navigation--is-safari';
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        if (this.isSafariBrowser) {
            this.el.classList.add(this.navigationIsSafariClass);
        }

        return true;
    }
}
