import 'bootstrap';
import 'jquery';
import 'devbridge-autocomplete';

import PluginManager from "./plugin-system/PluginManager";
import isIE11 from "./utilities/ie-detect";

import '../scss/app.scss';
import TextImageCombinationPlugin from "./plugins/TextImageCombinationPlugin";

import ScrollMenuPlugin from "./plugins/ScrollMenuPlugin";
import MobileNavigationPlugin from "./plugins/MobileNavigationPlugin";
import SafariNavigationPlugin from "./plugins/SafariNavigationPlugin";
import AnchorScrollPlugin from "./plugins/AnchorScrollPlugin";
import ExpanderPlugin from "./plugins/ExpanderPlugin";
import ServiceBadgePlugin from "./plugins/ServiceBadgePlugin";
import ScrollAnimationPlugin from "./plugins/ScrollAnimationPlugin";
import NewsTeaserPlugin from "./plugins/NewsTeaserPlugin";
import SearchPlugin from "./plugins/SearchPlugin";
import TabPlugin from "./plugins/TabPlugin";

// @ts-ignore
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/print/ls.print';
import NestedMenuPlugin from "./plugins/NestedMenuPlugin";
import BrickManagerPlugin from "./plugins/BrickManagerPlugin";
import ScrollTopPlugin from "./plugins/ScrollTopPlugin";
import DownloadsPlugin from "./plugins/DownloadsPlugin";

lazysizes.init();

if (isIE11) {
    // @ts-ignore
    import('picturefill');
}

document.addEventListener('DOMContentLoaded', function() {
    if (isIE11) {
        document.body.classList.add('is-ie');
    }
});

const pluginManager = new PluginManager();

pluginManager.registerPlugin(ScrollMenuPlugin, '.navigation');
pluginManager.registerPlugin(MobileNavigationPlugin, '.navigation');
pluginManager.registerPlugin(SafariNavigationPlugin, '.navigation');
pluginManager.registerPlugin(AnchorScrollPlugin, 'body');
pluginManager.registerPlugin(TextImageCombinationPlugin, '.text-image-combination');
pluginManager.registerPlugin(ExpanderPlugin, '[data-expander]');
pluginManager.registerPlugin(ScrollTopPlugin, '[data-scroll-top]');
pluginManager.registerPlugin(ServiceBadgePlugin, '.service-badge');
pluginManager.registerPlugin(NestedMenuPlugin, '.mega-menu__container');
pluginManager.registerPlugin(ScrollAnimationPlugin, 'body');
pluginManager.registerPlugin(NewsTeaserPlugin, '.news-teaser');
pluginManager.registerPlugin(BrickManagerPlugin, '.content-main');
pluginManager.registerPlugin(DownloadsPlugin, '.downloads__container');
pluginManager.registerPlugin(SearchPlugin, '.js-navigation__search');
pluginManager.registerPlugin(TabPlugin, '[data-tab-container]');
